@import '../../../../scss/theme-bootstrap';

.footer-links {
  &__content {
    .footer-links__title {
      font-family: $font--heading;
      font-size: 14px;
      cursor: pointer;
      font-weight: 700;
      @include breakpoint($landscape-up) {
        cursor: default;
        pointer-events: none;
      }
    }
    .footer-links__mobile-content-toggle {
      display: inline-block;
      float: $rdirection;
      height: 15px;
      width: 15px;
    }
    &[aria-expanded='true'] {
      .icon-minus {
        display: block;
      }
      .icon-plus {
        display: none;
      }
      .footer-links__mobile-content-toggle {
        @include breakpoint($landscape-up) {
          display: none;
        }
      }
    }
    &[aria-expanded='false'] {
      .icon-minus {
        display: none;
      }
      .icon-plus {
        display: block;
      }
      .footer-links__mobile-content-toggle {
        @include breakpoint($landscape-up) {
          display: none;
        }
      }
    }
    .footer-links__section {
      height: auto;
      transition: height 0.3s ease-in;
      &[aria-hidden='true'] {
        height: 0;
        overflow: hidden;
        display: none;
        @include breakpoint($landscape-up) {
          height: auto;
          overflow: unset;
          display: block;
        }
      }
      &[aria-hidden='false'] {
        display: block;
      }
    }
  }
  &__link {
    padding-top: 5px;
    @include breakpoint($landscape-up) {
      padding-top: 4px;
    }
    &-cta {
      font-size: 15px;
      text-decoration: none;
      &:after,
      &:before {
        visibility: hidden;
      }
      &:hover {
        &:after,
        &:before {
          visibility: visible;
        }
      }
    }
    &-title {
      font-size: 15px;
    }
    &:last-child {
      padding-bottom: 5px;
    }
  }
}
